body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima';
}

@media screen and (max-width: 767px){
  .mobile-form {
    min-height: 350px !important;
  }
  .mobile-row {
    margin: 0 15px
  }
  .mobile-or {
    top: 71% !important;
  }
  .mobile-time {
    font-size: 750% !important;
  }
}
