* {
  font-family: Proxima, sans-serif;
}

@font-face {
    font-family: 'Proxima';
    src: url('../assets/fonts/ProximaNova-Regular.eot');
    src: url('../assets/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/ProximaNova-Regular.woff') format('woff'),
         url('../assets/fonts/ProximaNova-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima';
    src: url('../assets/fonts/ProximaNova-RegularIt.eot');
    src: url('../assets/fonts/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/ProximaNova-RegularIt.woff') format('woff'),
         url('../assets/fonts/ProximaNova-RegularIt.ttf') format('truetype'),
         url('../assets/fonts/ProximaNova-RegularIt.svg#ProximaNova-RegularIt') format('svg');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Material Icons';
    src: url('../assets/fonts/MaterialIcons-Regular.eot');
    src: url('../assets/fonts/MaterialIcons-Regular.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/MaterialIcons-Regular.woff') format('woff'),
         url('../assets/fonts/MaterialIcons-Regular.ttf') format('truetype'),
         url('../assets/fonts/MaterialIcons-Regular.svg#MaterialIcons') format('svg');
    font-weight: 400;
    font-style: normal;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.icon-pulse {
  -webkit-animation: icon-spin 1s infinite steps(8);
  animation: icon-spin 1s infinite steps(8);
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
